import { useState } from 'react';
import axios from 'axios';

function useLoadData() {
    const [datas, setDatas] = useState([]);

    const loadData = (route) => {
        axios.get(route)
        .then(reponse => {
            const Games = Object.values(reponse.data);
            const GamesToAdd = [];
            
            Games.map(game => {
                return (
                    GamesToAdd.push(game)
                    );
                })
                
                setDatas(GamesToAdd);
        })
        .catch(error => {
            console.log(error)
        })
    }
    return [datas, loadData];
}

export default useLoadData;