import React, {Component} from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Homepage from './containers/Homepage/Homepage';
import GamesManager from './containers/GamesManager/GamesManager';
// import Contact from './containers/Contact/Contact';
import GamePage from './components/Games/GamePage';



class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter basename='/'>
              <Switch>
                <Route path="/" exact render={() => <Homepage />} />
                <Route path="/games" exact render={(props) => <GamesManager {...props} />} />
                <Route path="/games/admin/:admin" render={(props) => <GamesManager {...props} admin={props.match.params.admin} />} />
                <Route path="/games/:nom" render={(props) => <GamePage slug={props.match.params.nom} />} />
                {/* <Route path="/contact" exact component={Contact} /> */}
                <Route render={() => <h1>La page 404 là</h1>} />
              </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default App;