import React, {Component} from 'react';
import Game from './Game/Game';
import TitreH2 from '../Titres/TitreH2';

class Games extends Component {
    render() {
        return (
            <>
                <div className='container'>
                    <TitreH2 className="mt-4 mb-3 d-flex justify-content-between align-items-start">
                        <span>Jeux indés</span>
                        
                        {this.props.searchGame.length < 3 &&
                        <p className='mb-0'>
                            <small>Page {this.props.currentPage} ({this.props.minGames} - {this.props.maxGames})</small>
                        </p>
                        }

                        <p className='mb-0'>
                            <small>{this.props.countGames} sur {this.props.count} existants</small>
                        </p>
                    </TitreH2>
                    {this.props.loading 
                    && 
                        <div>
                            <img src='https://media2.giphy.com/media/dAcUSqS5cT5oIt75jS/giphy.gif?cid=790b7611b213d5584648958f8b02cb18291f5137a0408421&rid=giphy.gif&ct=s' alt="loader" />
                        </div>
                    }

                    <div className='row no-gutters'>
                        {
                            this.props.games.map(jeu => {
                                return (
                                    <>
                                        {this.props.searchGame.length < 3
                                        ? <Game isAdmin={this.props.isAdmin} addToDB={this.props.addToDB} path={this.props.path} key={jeu.slug} {...jeu} />
                                        : <>{jeu.slug.includes(this.props.searchGame) && <Game isAdmin={this.props.isAdmin} addToDB={this.props.addToDB} path={this.props.path} key={jeu.slug} {...jeu} />} </>
                                        }
                                    </>
                                );
                            })
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default Games;