import React from 'react';
import { NavLink } from 'react-router-dom';

const Nav = (props) => {
    return(
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container d-flex justify-content-between">
                        <NavLink exact className="navbar-brand" to="/">{props.children}</NavLink>
                        <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to="/">Accueil</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to="/games">Jeux indés</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to="/contact">Contact</NavLink>
                                </li>
                            </ul>
                    </div>
                </nav>
        </>
    );
}

export default Nav;