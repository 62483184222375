import React, {Component} from 'react';
import TitreH1 from '../../components/Titres/TitreH1';
/* import Nav from '../../components/Nav/Nav';
import TitreH4 from '../../components/Titres/TitreH4'; */
import FavGames from '../FavGames/FavGames';
import Contact from '../Contact/Contact';
import instantgaming from '../../img/igpartner.gif';
import LogoAmazon from '../../img/amazon.svg';

class Homepage extends Component {

    componentDidMount = () => {
        document.title = `miikeu - Indie Games`;
    }
    render() {
        return (
            <>
                {/* <Nav><TitreH4 className="mb-0 mt-1">My Indie Games - Home</TitreH4></Nav> */}

                <div className="bg-single-game">
                    <img src="https://media.rawg.io/media/games/e0f/e0f05a97ff926acf4c8f43e0849b6832.jpg" alt="bg games" />   
                </div>
                
                <Contact />
                <div className='container my-5'>
                    <div className='row no-gutters'>
                        <div className='col-12 col-md-7'>
                            <TitreH1>Mes recommandations de jeux indés</TitreH1>
                            <p className='w-100'>Vous trouverez ci-dessous une sélection des jeux que j'ai présenté sur TikTok et que je vous conseille donc (pour la plupart) ! Les liens d'achat et du TikTok en question sont également présents sur cette page.</p>
                        </div>
                        <div className='col-12 mb-4'>
                            <FavGames />
                        </div>

                        <div class="row line">
                            <div className="col-12">
                                <img className="mx-2" src={LogoAmazon} alt="Amazon" /> <a href="http://amazon.miik.eu" rel="noreferrer" target='_blank'>Mes recommandations Tech</a>
                            </div>
                        </div>
                        
                        <div className='col-12 mt-4'>
                            <a href='https://www.instant-gaming.com/?igr=mikeu' rel='noreferrer' title='Instant Gaming' target={'_blank'}>
                                <img src={instantgaming} alt="Instant Gaming Partner" />
                            </a>
                        </div>

                        <div className='col-12'>
                            <iframe title='Twitch.tv/miikeu' className='mt-5 w-100' src="https://player.twitch.tv/?channel=miikeu&parent=liens.miik.eu" frameBorder="0" allowFullScreen={true} scrolling="no" height="378" width="620"></iframe>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Homepage;