import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import Bouton from '../../Boutons/Bouton';

class Game extends Component {
    state = {
        steam : "",
        ig: "",
        tiktok: ""
    }
    render() {
        return (
            <>
                {this.props.fav === "oui" ?
                    <div className='col-12 col-md-6 col-lg-4 mb-3'>
                        <div className='card bg-dark p-3 text-center h-100 d-flex flex-row align-items-center justify-content-between shadow gameSingle gameSingle--fav'>
                            <div className='d-flex align-items-center'>
                                <img src={this.props.background_image} alt={this.props.name} />
                                <div className='my-0 text-left d-flex flex-column align-items-start'>
                                    <span className='text-start'>{this.props.name}</span>
                                    <p className='mt-2 mb-0 text-start'>
                                        {(this.props.steam.length > 0) && <a className='badge rounded-pill bg-black mx-1' href={this.props.steam} rel="noreferrer" target="_blank">Steam</a>}
                                        {(this.props.ig.length > 0) && <a className='badge rounded-pill bg-danger mx-1' href={this.props.ig} rel="noreferrer" target="_blank">Instant Gaming</a>}
                                        {(this.props.tiktok.length > 0) && <a className='badge rounded-pill bg-white mx-1' href={this.props.tiktok} rel="noreferrer" target="_blank"><img src="https://liens.miik.eu/old/img/tiktok.svg" alt="Tiktok" /></a>}
                                    </p>
                                </div>
                            </div>
                            <NavLink to={this.props.path + '/' + this.props.slug} className="btn btn-outline-info">
                            ➤
                            </NavLink>
                        </div>
                    </div>
                :
                    <div className='col-6 col-sm-4 col-md-3 mb-3'>
                        <div className='card bg-dark p-3 position-relative text-center h-100 d-flex flex-column justify-content-between shadow gameSingle'>
                            {this.props.metacritic &&
                                <div className='metacritic-badge badge rounded-pill bg-success'>
                                    <img src='https://pbs.twimg.com/profile_images/527528131171590144/EQXs3lpX_400x400.png' alt="metacritic" />
                                    {this.props.metacritic}
                                </div>
                            }
                            <div>
                                <img src={this.props.background_image} alt={this.props.name} />
                                <p className='mt-2 text-left'>{this.props.name}</p>
                            </div>
                            
                            {this.props.isAdmin === "oui" 
                            ? 
                                <>
                                    <div>
                                    <input type="text" className='mb-1 form-control' value={this.state.steam} onChange={(event) => this.setState({steam:event.target.value})} placeholder='Lien steam' />
                                    <input type="text" className='mb-1 form-control' value={this.state.ig} onChange={(event) => this.setState({ig:(event.target.value+'?igr=mikeu')})} placeholder='Lien IG' />
                                    <input type="text" className='mb-1 form-control' value={this.state.tiktok} onChange={(event) => this.setState({tiktok:event.target.value})} placeholder='Lien Tiktok' />
                                    <Bouton className="btn-primary btn-sm" click={() => this.props.addToDB(this.props.id, this.props.name, this.props.background_image, this.props.slug, this.state.steam, (this.state.ig),this.state.tiktok)}>Add to list</Bouton>
                                    </div>
                                </>
                            :
                                <NavLink to={this.props.path + '/' + this.props.slug} className="btn btn-secondary btn-sm">
                                    En savoir plus
                                </NavLink>
                            }
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default Game;