import React, {Component} from 'react';
import Nav from '../../components/Nav/Nav';
import TitreH4 from '../../components/Titres/TitreH4';
import Games from '../../components/Games/Games';
import axios from 'axios';
import Pagination from '../Pagination/Pagination';
//import Bouton from '../../components/Boutons/Bouton';

class GamesManager extends Component {
    state = {
        count: 50118,
        games: [],
        loading: false,
        currentPage: 1,
        GamesParPage : 20,
        searchGame : ""
    }

    componentDidMount = () => {
        this.setState({loading:true})

        this.fetchGamesHandler()  
    }

    fetchGamesHandler = () => {
            axios.get('https://indie-games-53025-default-rtdb.europe-west1.firebasedatabase.app/jeux/datas.json')
            .then(response => {  
                this.setState({games:response.data, loading:false})
            })
            .catch(error => {
                console.log(error)
            })
    }

    toConvertTableGamesHandler = () => {
        console.log('pouet')
        console.log(this.state.games)
        const toAllGames = [];

            this.state.games.map(single => {
                
                for (let i = 0; i < single.length; i++) {                  
                    toAllGames.push(single[i])
                }

                return toAllGames;
            })

            this.setState({allgames:toAllGames})
    }

    toDbPushHandler = (id,nom,bg,slug,steam,ig,tiktok) => {
        const Jeu = {
            id : id,
            name : nom,
            background_image : bg,
            slug : slug,
            steam : steam,
            ig : ig,
            tiktok : tiktok
        }
        axios.post('https://myfavgames-b435e-default-rtdb.europe-west1.firebasedatabase.app/jeux.json', Jeu)
        
            .then(reponse => {
        
                this.setState({loading:false})
                console.log(Jeu)
            })
            .catch(error => {
                alert('Erreur lors de ajout');
        })
        
    }

    currentPageHandler = (currentPage) => {
        this.setState({currentPage})
    }

    render() {
        let maxGames = (this.state.GamesParPage * this.state.currentPage) + 1;
        let minGames = ((this.state.GamesParPage * this.state.currentPage) - this.state.GamesParPage) + 1;
        
        return (
            <>
                <Nav>
                    <TitreH4 className="mb-0 mt-1">My Indie Games - Liste</TitreH4>
                </Nav>
                
                <div className="bg-single-game">
                    <img src="https://media.rawg.io/media/games/e0f/e0f05a97ff926acf4c8f43e0849b6832.jpg" alt="bg games" />   
                </div>
                
                <input className='form-control mt-2 w-50 mx-auto rounded-5' type='text' value={this.state.searchGame} onChange={(event) => this.setState({searchGame:event.target.value})} placeholder='Rechercher un jeu' />
                

                {(this.state.games && this.state.searchGame.length >= 3)
                &&   
                    <Games 
                        isAdmin={(this.props.admin === 'miikeuadmin67') ? 'oui' : 'non'}
                        addToDB={this.toDbPushHandler}
                        currentPage={this.state.currentPage}
                        path={this.props.match.path} 
                        maxGames={maxGames} 
                        minGames={minGames} 
                        loading={this.state.loading} 
                        count={this.state.count} 
                        countGames={this.state.games.length}
                        searchGame={this.state.searchGame.toLowerCase()} 
                        games={this.state.games} 
                    />
                }

                {(this.state.games && this.state.searchGame.length < 3)
                &&   
                    <Games 
                        isAdmin={(this.props.admin === 'miikeuadmin67') ? 'oui' : 'non'}
                        addToDB={this.toDbPushHandler}
                        currentPage={this.state.currentPage}
                        path={this.props.match.path} 
                        maxGames={maxGames} 
                        minGames={minGames} 
                        loading={this.state.loading} 
                        count={this.state.count} 
                        countGames={this.state.games.length}
                        searchGame={this.state.searchGame.toLowerCase()}
                        games={this.state.games.slice(minGames,maxGames)} 
                    />
                }

                {((this.state.games.length > this.state.GamesParPage) && this.state.searchGame.length < 3)
                &&
                    <div className='container'>
                        <div className='row'>
                            <Pagination 
                                currentPageHandler={this.currentPageHandler} 
                                GamesParPage={this.state.GamesParPage} 
                                games={this.state.games} 
                                currentPage={this.state.currentPage} 
                            />
                        </div>
                    </div>
                }

            </>
        );
    }
}

export default GamesManager;