import { useEffect } from 'react';
import Game from '../../components/Games/Game/Game';
import useLoadData from '../../Hooks/useLoadDatas';

function FavGames() {
    const [games, loadGames] = useLoadData();
    
    useEffect(() => {
        loadGames('https://myfavgames-b435e-default-rtdb.europe-west1.firebasedatabase.app/jeux.json');
    }, [loadGames])


    return (

        <>
            
            <p className='badge rounded-pill bg-success'>{games.length} jeux sélectionnés</p>
            <div className='row no-gutters'>
                {
                    games.map(jeu => {
                        return (
                            <Game fav="oui" path="/games" key={jeu.slug} {...jeu} />
                        );
                    })
                }
            </div>
        </>

    );
}

export default FavGames;