import React, {Component} from 'react';
/* import Nav from '../../components/Nav/Nav';
import TitreH4 from '../../components/Titres/TitreH4'; */

class Contact extends Component {
    render() {
        return (
            <>
                {/* <Nav><TitreH4 className="mb-0 mt-1">My Indie Games - Contact</TitreH4></Nav> */}
                
                <div className="bg-single-game">
                    <img src="https://media.rawg.io/media/games/e0f/e0f05a97ff926acf4c8f43e0849b6832.jpg" alt="bg games" />   
                </div>

                <div className='container mt-5 w-md-50'>
                    <div className="row">
                        <div className="row-contact col-12 p-3 rounded-4">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-2 centered">
                                    <img className="rounded-5" src="https://static-cdn.jtvnw.net/jtv_user_pictures/b9145a36-e4c3-4074-88b8-dab31cef4cae-profile_image-70x70.png" alt="Miikeu" />
                                </div>
                                <div className="col-12 col-md-10">
                                    <h2>Streamer &amp; Tiktoker</h2>
                                    <p>
                                        31 ans et fan de jeux en tous genres et spécialement de jeux indépendants
                                        <br/>
                                        Partenaire officiel Instant Gaming : Profitez de réductions allant jusqu'à -90% avec mon lien : <a href="https://www.instant-gaming.com/?igr=mikeu" rel='noreferrer' target={'_blank'}>instant-gaming.com/?igr=mikeu
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 d-flex align-items-center justify-content-center">
                        <a className="col-2 py-3 mx-1 reseaux centered rounded-5 d-flex align-items-center justify-content-center" href="https://www.tiktok.com/@mikeukm" rel='noreferrer' target={'_blank'}>
                            <img src="https://liens.miik.eu/old/img/tiktok.svg" alt="Tiktok" />
                        </a>
                        <a className="col-2 py-3 mx-1 reseaux centered rounded-5 d-flex align-items-center justify-content-center" href="https://twitch.tv/miikeu" rel='noreferrer' target={'_blank'}>
                            <img src="https://liens.miik.eu/old/img/twitch.svg" alt="Twitch" />
                        </a>
                        <a className="col-2 py-3 mx-1 reseaux centered rounded-5 d-flex align-items-center justify-content-center" href="https://twitter.com/miikeu" rel='noreferrer' target={'_blank'}>
                            <img src="https://liens.miik.eu/old/img/twitter.svg" alt="Twitter" />
                        </a>
                        <a className="col-2 py-3 mx-1 reseaux centered rounded-5 d-flex align-items-center justify-content-center" href="https://www.youtube.com/@mikeukm" rel='noreferrer' target={'_blank'}>
                            <img src="https://liens.miik.eu/old/img/youtube.svg" alt="Youtube" />
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

export default Contact;