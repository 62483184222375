import React, {Component} from 'react';

class Pagination extends Component {
    render() {
        const nbPages = Math.ceil(this.props.games.length / this.props.GamesParPage);

        let page = [];
        for (let i = 0; i < nbPages; i++) {
            let classeActive = "pagination--page m-1 d-inline-block btn btn-outline-primary";
            const bonCalculI = (i + 1);

            if(this.props.currentPage === bonCalculI) classeActive = "pagination--page m-1 d-inline-block btn btn-primary"
            
            if((bonCalculI === this.props.currentPage) || (bonCalculI === 1) || (bonCalculI === nbPages) || (bonCalculI === (this.props.currentPage - 2)) || (bonCalculI === (this.props.currentPage - 1)) || (bonCalculI === (this.props.currentPage + 2)) || (bonCalculI === (this.props.currentPage + 1))) {
                page.push(<span key={i} className={classeActive} onClick={() => this.props.currentPageHandler(i + 1)}>{i + 1}</span>);
             } else if((bonCalculI === (this.props.currentPage - 3)) || (bonCalculI === (this.props.currentPage + 3))) {
                page.push(<span key={i} className="btn btn-outline-primary disabled">[...]</span>);
             } 
        }

        return (
            <>
                <div className='pagitation text-center mb-3'>
                    {page}
                </div>
            </>
        );
    }
}

export default Pagination;