import React, {Component} from 'react';
import axios from 'axios';
// import Nav from '../Nav/Nav';
import TitreH1 from '../Titres/TitreH1';
import TitreH4 from '../Titres/TitreH4';
import { NavLink } from 'react-router-dom';

class GamePage extends Component {
    state = {
        data : null,
        screenshots : null
      }
      componentDidMount = () => {
        this.setState({loading:true})
      
        axios.get(`https://api.rawg.io/api/games/${this.props.slug}?key=bc1e334fbec3475cb23fd866174edb9c`)
          .then(reponse => {
            const Jeu = reponse.data;
      
            this.setState({data:Jeu})
            
            document.title = `MIG - ${this.state.data.name}`;
          })
          .catch(error => {
            console.log(error)
          })

          axios.get(`https://api.rawg.io/api/games/${this.props.slug}/screenshots?key=bc1e334fbec3475cb23fd866174edb9c`)
          .then(reponse => {
            const screenshots = reponse.data;
      
            this.setState({screenshots})
          })
          .catch(error => {
            console.log(error)
          })
      }

    render() {
        return (
            <>
                {this.state.data &&
                    <>
                        {/* <Nav><TitreH4 className="mb-0 mt-1">My Indie Games - {this.state.data.name}</TitreH4></Nav> */}
                        <NavLink exact className="navbar-brand d-block mt-2 mx-2 mb-0" to="/">Retour à l'Accueil</NavLink>
                        <div className='bg-single-game'>
                            <img  src={this.state.data.background_image} alt={this.props.slug} />
                        </div>
                        
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-12 mb-3'>
                                        <TitreH1 className="mb-0">{this.state.data.name}</TitreH1>
                                        {this.state.data.publishers[0].name && <small>by {this.state.data.publishers[0].name}</small>}
                                </div>
                            </div>

                            <div className='row row-stats-game mb-2'>
                                <div className='col-6 col-md-3'>
                                    <TitreH4>Année de sortie</TitreH4>
                                    {this.state.data.released && <TitreH1>{this.state.data.released.slice(0,4)}</TitreH1>}
                                </div>
                                <div className='col-6 col-md-3'>
                                    <TitreH4>Genres</TitreH4>
                                    {
                                        this.state.data.genres.map(genre => {
                                            return (
                                                <p className='badge rounded-pill bg-success m-1' key={genre.id}>{genre.name}</p>
                                            );
                                        })
                                    }
                                </div>
                                <div className='col-6 col-md-3'>
                                    <TitreH4>Jouable sur:</TitreH4>
                                    {
                                        this.state.data.stores.map(store => {
                                            return (
                                                <p className='badge rounded-pill bg-danger m-1' key={store.id}>{store.store.name}</p>
                                            );
                                        })
                                    }
                                </div>
                                <div className='col-6 col-md-3'>
                                    <TitreH4>Score Metacritic</TitreH4>
                                    <TitreH1>{this.state.data.metacritic ? this.state.data.metacritic : '/'}</TitreH1>
                                </div>
                            </div>
                            
                            <div className='row'>
                                <div className='col-12 mb-5'>
                                    {
                                        this.state.data.tags.map(tag => {
                                            return (
                                                <p className='badge rounded-pill bg-dark m-1' key={tag.id}>#{tag.name}</p>
                                            );
                                        })
                                    }
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-12'>
                                        <p>{this.state.data.description_raw}</p>
                                </div>
                            </div>

                        </div>
                    </>
                }

                {this.state.screenshots &&
                    <>
                        <div className='d-flex flex-wrap justify-content-center gap-2 mt-5'>
                                {
                                    this.state.screenshots.results.map(screen => {
                                        return (
                                            <div className='sup-screens'><img className='w-100' key={screen.id} src={screen.image} alt={this.props.slug} /></div>
                                        );
                                    })
                                }
                        </div>
                    </>
                }
            </>
        );
    }
}

export default GamePage;